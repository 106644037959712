import React, { Component } from "react";
import "./index.css";
import styled from "styled-components";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Delighters from "./Components/ReactDelighters";
import { Helmet } from "react-helmet";

const navbarHeight = 60;

class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname },
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = true;

    if (pathname === "/") {
      withPaddingTop = false;
    }

    return (
      <Delighters>
        <Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>RevCertCard 智能標籤</title>
            <meta
              name="description"
              content="智能手環 (感官 x 忻旅)"
              description='感官文化印刷,忻旅科技,萬象創造'
            />
          </Helmet>


          <Content>{this.props.children}</Content>

        </Wrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: ${props => (props.withPaddingTop ? navbarHeight : 0)}px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export default Layout;
