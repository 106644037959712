import React from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import { StaticImage } from "../Components/StaticImage";
import { s, d } from "../Utils/Lang";
import { SwapSpinner } from "react-spinners-kit";
import Img from "gatsby-image";
import SensLogo from "../images/sens-logo.png";
import RevtelLogo from "../images/revtel-logo-04.png";

import SensLogo2 from "../images/logo-desktop.png";
import RevtelLogo2 from "../images/revtel-logo2.png";

import SensLogo3 from "../images/SensLogo.png";
import RevtelLogo3 from "../images/RevLogo.png";

const DEST_URL = "https://sensationsprint.com.tw/";
const DEBUG = false;

class LandingPage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.location.href = DEST_URL;
    }, 1000 * (DEBUG || window.location.href.indexOf("debug=1") !== -1 ? 1000 : 1.5));
  }

  render() {
    return (
      <Wrapper>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>智慧感應，隨心跳轉</h2>
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <a
              href="https://sensationsprint.com.tw"
              style={{ color: "inherit", textDecoration: "inherit" }}
              target="_blank"
            >
              <img src={SensLogo3} style={{ width: 70, height: 70 }} />
            </a>
            <a
              href="https://www.revtel.tech"
              style={{ color: "inherit", textDecoration: "inherit" }}
              target="_blank"
            >
              <img
                src={RevtelLogo3}
                style={{ width: 80, height: 80, marginLeft: 10 }}
              />
            </a>
          </div>
          <SwapSpinner />
        </div>

        <div
          style={{
            width: 300,
            height: 100,
            fontWeight: 400,
            padding: 20,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="https://sensationsprint.com.tw"
            style={{ color: "inherit", textDecoration: "inherit" }}
            target="_blank"
          >
            感官文化印刷 SENSATIONS PRINT
          </a>

          <a
            href="https://www.revtel.tech"
            style={{ color: "inherit", textDecoration: "inherit" }}
            target="_blank"
          >
            忻旅科技股份有限公司 RevtelTech
          </a>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: #eeeeee;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default withPage(LandingPage);
