import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import * as Icon from "./Icon";

const background = "#e6e6e6";
const altBackground = "#e6e6e6";
const textColor = "#4E4E4E";
const textShadow = "2px 2px #ccc";
const breakpoint = 800;
const navbarHeight = 60;
const navItems = [
  { children: "Products", url: "/products" },
  { children: "Articles", url: "/articles" },
  { children: "Stores", url: "/stores" },
  { children: <Icon.Person size={36} />, url: "/me" },
];

class Navbar extends React.Component {
  state = {
    openMenu: false,
  };

  render() {
    let { passBreakpoint } = this.props;

    return (
      <Style.Wrapper passBreakpoint={passBreakpoint}>
        <Style.Content>
          <Link to="/" className="site-title">
            RevBase
          </Link>

          {this._smallScreenNavBar()}
          {this._largeScreenNavBar()}
        </Style.Content>
      </Style.Wrapper>
    );
  }

  _smallScreenNavBar() {
    const toggleMenuState = () =>
      this.setState({ openMenu: !this.state.openMenu });
    return (
      <Style.SmallScreenWrapper breakpoint={breakpoint}>
        <div style={{ width: 40, height: 40 }} onClick={toggleMenuState}>
          <Icon.Apps />
        </div>

        <Style.SmallScreenMenu
          open={this.state.openMenu}
          onClick={toggleMenuState}
        >
          <div className="container">
            {[
              { children: <Icon.Home color="white" />, url: "/" },
              ...navItems,
            ].map(({ children, url }, idx) => (
              <Link to={url} key={idx} className="nav-item">
                {children}
              </Link>
            ))}
          </div>
        </Style.SmallScreenMenu>
      </Style.SmallScreenWrapper>
    );
  }

  _largeScreenNavBar() {
    return (
      <Style.LargeScreenWrapper breakpoint={breakpoint}>
        {navItems.map(({ children, url }, idx) => (
          <Link to={url} key={idx} className="nav-item">
            {children}
          </Link>
        ))}
      </Style.LargeScreenWrapper>
    );
  }
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 20px;
    height: ${navbarHeight}px;
    z-index: 10;
    border-bottom: 0.5px solid #bdbdbd;
    background: ${props => (props.passBreakpoint ? altBackground : background)};
    transition: background 200ms ease-in-out;
  `,

  Content: styled.div`
    margin: 0 auto;
    max-width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;

    & > .site-title {
      color: ${textColor};
      text-shadow: ${textShadow};
      text-decoration: none;
      font-size: 30px;
    }
  `,

  LargeScreenWrapper: styled.div`
    @media screen and (max-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > .nav-item {
      margin-right: 15px;
      color: ${textColor};
      text-shadow: ${textShadow};
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    & > .nav-item :last-child {
      margin-right: 0px;
    }
  `,

  SmallScreenWrapper: styled.div`
    @media screen and (min-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `,

  SmallScreenMenu: styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${props => (props.open ? "0" : "-100vw")};
    background-color: ${props =>
      props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
    transition: background-color 250ms linear,
      left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
    z-index: 5;

    & > .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding-top: 30px;

      & > .nav-item {
        color: ${textColor};
        height: 50px;
        text-decoration: none;
      }
    }
  `,
};

export default Navbar;
