import React from "react";
import { connect, Provider } from "react-redux";
import { getStore } from "./Store";
import Layout from "./Layout";
import Selectors from "./Selectors";
import ActionCreator from "./ActionCreator";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
    console.warn("*** Error Boundary ***", error, info);
  }

  render() {
    let { error } = this.state;

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: 20, backgroundColor: "grey" }}>
            <h2 style={{ color: "white" }}>Oops! We have errors!</h2>
            <p style={{ color: "#ddd" }}>Please reload the page</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

class Page extends React.Component {
  constructor(props) {
    super(props);
    let { appActions, pageContext } = this.props;
  }

  async componentDidMount() {
    let { appActions, location } = this.props;
    // TODO: we will deprecate the Service module, so ga service needs to be re-written
    // appActions.ga.trackingPageView(location.pathname + location.search);
    // if (!getStore().getState().user.data) {
    //   await appActions.autoLogin();
    // }
  }

  render() {
    return (
      <ErrorBoundary>
        <Layout key={this.props.lang} {...this.props}>
          {this.props.children}
        </Layout>
      </ErrorBoundary>
    );
  }
}

Page = connect(
  (state, ownProps) => ({ lang: Selectors.getLang(state) }),
  ActionCreator
)(Page);

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    componentWillMount() {
      let { pageContext } = this.props;
      // create redux store
      getStore(pageContext.initialState);
    }

    render() {
      return (
        <Provider store={getStore()}>
          <Page {...this.props}>
            <Comp {...this.props}>{this.props.children}</Comp>
          </Page>
        </Provider>
      );
    }
  }

  PageWrapper.displayName = "Page-" + Comp.displayName;
  return PageWrapper;
}
