import React from "react";
import { withPage } from "../PageContainer";
import { Link } from "gatsby";
import SEO from "../Components/seo";

const NotFoundPage = () => (
  <div style={{ margin: 20 }}>
    <SEO title="404: Not found" />
    <h2>NOT FOUND</h2>
    <Link to="/">Back to home</Link>
  </div>
);

export default withPage(NotFoundPage);
