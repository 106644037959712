import { navigate } from "gatsby";
import Actions from "./Reducers/Actions";
import Catchify from "./Utils/Catchify";
import Api from "./Api";

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    }),
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    echo: async () => {
      return await Api.echo("hello");
    },
  },
});
