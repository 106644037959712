export default function Catchify(fn, onCatch) {
  let wrapper = async (...args) => {
    try {
      return await fn(...args);
    } catch (ex) {
      if (onCatch) {
        return await onCatch(ex);
      }
      console.warn(ex);
      throw ex;
    }
  };

  return wrapper;
}
